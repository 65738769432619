@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/sass/abstracts/mixins";














































.error {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 144px 72px;

  @include mq_l() {
    flex-direction: row-reverse;
    padding: $size-l $size-xl;
  }

  @include mq_s() {
    display: block;
  }

  &__details {
    @include mq_s() {
      text-align: center;
    }

    .details__title {
      max-width: 648px;
      padding-bottom: $size-s;
      color: $color_main;

      @include mq_l() {
        font-size: $font-size-heading-5;
      }
    }

    .details__error-type {
      padding-bottom: $size-l;
      color: $color-ink-light;

      @include mq_l() {
        padding-bottom: $size-s;
        font-size: $font-size-xs;
      }
    }

    .details__comment {
      max-width: 656px;
      padding-bottom: $size-xl;
      color: $color-ink-light;

      @include mq_l() {
        padding-bottom: $size-l;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
      }
    }
  }

  &__ilustration {
    @include mq_l() {
      align-self: flex-start;
      width: 50%;
    }

    @include mq_s() {
      width: 100%;
      padding-bottom: $size-s;
    }
  }
}
