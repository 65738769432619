@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"

































.tv-spinner
  display: inline-block
  border-radius: 100%
  width: 48px
  height: 48px
  transition: all 0.3s linear
  animation: spin 0.8s linear infinite

  &--big
    width: 64px
    height: 64px

  &--regular
    width: 48px
    height: 48px

  &--small
    width: 24px
    height: 24px
    border-width: 4px

  &--tiny
    width: 18px
    height: 18px
    border-width: 4px

  &--accent
    border: 5px solid $color-ink-lighter
    border-top: 5px solid $color-primary

  &--dark
    border: 5px solid rgba(255,255,255, .4)
    border-top: 5px solid white

@keyframes spin
 0%
  transform: rotate(0deg)
 100%
  transform: rotate(360deg)

