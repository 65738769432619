@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"

























































@import "~@sas-te/alfabeto-vue"

body
  background: $color-ice

.app-main
  margin-top: 71px
