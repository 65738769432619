@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"


































.badge
  display: inline-block
  text-align: center
  font-size: 13px
  padding: 4px 8px
  border-radius: 100px
  box-sizing: border-box

  &--success
    color: $color-success !important
    background: transparentize($color-success, .75) !important

  &--progress
    color: $color-secondary !important
    background: transparentize($color-secondary, .75) !important

  &--fail
    color: red !important
    background: transparentize(red, .75) !important

