@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"








.wrapper
  background: #fff
  max-width: 1032px
  margin: 0 auto

  +mq-xl
    max-width: 992px

  +mq-l
    max-width: 720px

  +mq-m
    max-width: calc(100% - 48px)

  +mq-s
    max-width: calc(100% - 32px)

