@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"


















































$bone-color: transparentize($color-ink, .8)

.skeleton-bone
  filter: brightness(100%)
  background: $bone-color
  position: relative
  overflow: hidden
  display: inline-block

  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(90deg, transparent 0%, $bone-color 50%, transparent 80%)
    animation-duration: 1200ms
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-name: pulse
    animation-timing-function: ease-in-out


@keyframes pulse
  0%
    transform: translate3d(-80%, 0, 0)
  100%
    transform: translate3d(120%, 0, 0)

