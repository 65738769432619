@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"






















































$shadow-color: transparentize($color-ink, 0.6)

=elevation($z)
  @if $z == 1
    box-shadow: 0px 2px 6px -1px $shadow-color
  @if $z == 2
    box-shadow: 0px 6px 8px -1px $shadow-color
  @if $z == 3
    box-shadow: 0px 10px 16px -2px $shadow-color
  @if $z == 4
    box-shadow: 0px 24px 32px -6px $shadow-color

.tv-box
  color: $color-ink
  box-sizing: border-box
  background: white
  border: 1px solid transparentize($color-ink-lightest, 0.6)
  transition: all 200ms ease-in-out

  @for $i from 1 through 4
    &--#{$i}
      +elevation($i)

  @for $i from 1 through 4
    &--rise-on-hover-to-#{$i}:hover
      +elevation($i)

