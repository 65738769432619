@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"
// ------------------------------------------------------------
// Default Overrides
// ------------------------------------------------------------
$select-height: 40px

.multiselect
  min-height: $select-height

  &--active > &__tags
    box-shadow: inset 0px 2px 4px -1px rgba(9, 30, 66, 0.15), 0 0 0 1.6px $color-primary
    transition: all 150ms ease-in-out
    border-radius: 8px !important

  &--active > &__select
    transform: rotate(0)


  &__spinner
    height: 100%
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 50%), white
    border: 1px solid $color-ink
    display: block
    top: 0
    right: 0
    border-radius: 0 8px 8px 0
    border-left: 0

    &::before,
    &::after
      border-top-color: $color-primary

  &__input,
  &__single
    margin: 0
    padding: 0
    color: $color-ink
    background: none
    caret-color: $color-primary
    font-weight: 500

  &__input::placeholder
    color: $color-ink

  &__tags
    cursor: pointer
    border-radius: 8px
    min-height: $select-height
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 50%), white
    border: 1px solid $color-ink
    display: flex
    align-items: center
    padding: 0 16px
    padding-right: 48px

  &__placeholder
    color: $color-ink
    font-weight: 500
    padding: 0
    margin: 0

  &__select
    width: $select-height
    height: 100%
    top: 0
    right: 0

    &::before
      display: block
      width: 24px
      height: 24px
      position: absolute
      margin-top: 2px
      right: 8px
      top: 8px
      border: none
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='8'%3E%3Cuse fill='%23B3B5BA' transform='translate%281 1.5%29' xlink:href='%23path0_stroke'/%3E%3Cdefs%3E%3Cpath id='path0_stroke' d='M.7-.7A1 1 0 0 0-.7.7L.7-.7zM5 5l-.7.7a1 1 0 0 0 1.4 0L5 5zM10.7.7A1 1 0 0 0 9.3-.7L10.7.7zM-.7.7l5 5 1.4-1.4-5-5L-.7.7zm6.4 5l5-5L9.3-.7l-5 5 1.4 1.4z'/%3E%3C/defs%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-position: center center

  &__content-wrapper
    transform: translateY(4px)
    box-shadow: 0px 10px 16px -2px transparentize($color-ink, 0.6)
    border-radius: 8px
    border-color: $color-ink

  &__option
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 16px
    min-height: 48px
    border-bottom: 1px solid $color-ink-lightest

    &--highlight
      background: $color-primary

    &--highlight:after
      content: ""
      background: $color-primary

    &--selected
      background: $color-ink-lightest

      &:after
        content: ""
        padding: 0
        margin: 0
        left: auto !important
        top: 14px
        right: 14px
        width: 24px
        height: 24px
        background-repeat: no-repeat
        backgroud-position: center center
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath stroke='%2378809A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17 1.8l-11 11-5-5'/%3E%3C/svg%3E")

  .multiselect__option--selected.multiselect__option--highlight
    background: $color-primary

    &:after
      content: ""
      width: 24px
      height: 24px
      left: auto !important
      top: 14px
      right: 14px
      background-repeat: no-repeat !important
      backgroud-position: center center !important
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 1.9l-12 12M1 1.9l12 12'/%3E%3C/svg%3E")
