@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"
























































































































































































.app-main
  &__menu
    margin-bottom: 70px

    .content-menu
      z-index: 10

  &__loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)
